<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import getters from "../stores/login-store/getters/login-getters";

export default {
  name: "authentication",
  data() {
    return {
      isEditing: true
    }
  },
  watch: {
    "$route.name"(name) {
      if (name === "SignupPage") {
        this.$store.commit('setSelectedStep', getters.SIGN_UP_CREATE_ACCOUNT());
      } else if (name === "SigninPage") {
        this.$store.commit('setSelectedStep', getters.LOGIN_FORM());
      }
    }
  },
}
</script>

<style scoped>
</style>
