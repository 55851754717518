<template>
  <div>
    <UpperNavbar></UpperNavbar>
    <div class="row">
      <div class="col-md-4 col-sm-12">
        <ProgressBar></ProgressBar>
      </div>
      <div class="col-md-8 col-sm-12">
        <div>
          <div class="content-margins new-personal-info main-body">
            <p class="auth-p">Tell Us About Your Organization</p>
            <form novalidate="novalidate" class="simple_form new_user" accept-charset="UTF-8" method="post">
              <fieldset style="margin-top:5rem;">

                <div class="input optional mb-5 input-theme">
                  <label class="business-info-label">Organization Name</label>
                  <input tabindex="2" class="string optional organization-name" type="text"
                         v-model="form.name"
                         placeholder="Organization Name*">
                </div>

                <div class="input optional input-theme">
                  <label class="business-info-label">Theme Color</label>

                  <div class="display-color">
                    <div v-for="(option, index) in themes">
                      <div :class="option.id === form.theme_id ? 'active-color' : ''"
                           :style="[option.id === form.theme_id ?{ 'border':'2px solid'+option.primary_color}:'']">
                        <div class="theme-colors"
                             :key="index"
                             :style="{ 'background-color': option.primary_color}"
                             @click="selectedColor(option.id)">
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <div class="business-footer-div">
                  <input
                      type="button"
                      @click="back"
                      value="Back"
                      tabindex="4"
                      class="btn button back-btn">
                  <input
                      style=""
                      type="button"
                      @click="submit"
                      value="Next"
                      tabindex="4"
                      class="btn button next-btn">
                </div>

              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from "axios";
import ApiUrls from "../../api-url";
import {mapGetters, mapState} from "vuex";
import getters from "../../stores/login-store/getters/login-getters";
import AuthenticationMixin from "../../authentication-mixin";
import ProgressBar from "../shared/progress-bar"
import UpperNavbar from "../shared/upper-navbar";

export default {
  mixins: [ApiUrls, AuthenticationMixin],
  components: {ProgressBar, UpperNavbar},
  data() {
    return {
      form: {
        name: '',
        theme_id: null,
        url_param: '',
        amount: ''
      },
      themes: [],
    };
  },
  async created() {
    const response = await axios.get(this.accountUrl + "themes");
    this.themes = response.data.result;
    await this.get_info();
    if (this.urlParam) this.form.url_param = this.urlParam;
    if (this.trialAmount) this.form.amount = this.trialAmount;
    if (this.superOrg) this.form.superOrg = this.superOrg;
  },
  computed: {

    ...mapState(['trialAmount', 'trialDays', 'urlParam', 'currentPlan', 'superOrg']),
    ...mapGetters(['SIGN_UP_PERSONAL_INFO', 'SIGN_UP_PAYMENT_INFO'])
  },
  beforeCreate: function () {
    document.body.style.backgroundColor = '#FFFFFF';
  },
  methods: {
    selectedColor(id) {
      this.form.theme_id = id;
    },
    isValid() {
      if (!this.form.name) {
        this.$toast.show('Organization name required.', this.toasted_options.error);
        return false;
      }
      return true;
    },
    async submit() {
      if (this.isValid()) {
        var response = await axios.post(this.organizationUrl + "signup_business_info", this.form);
        if (response.data.success) {
          this.$store.commit('setSelectedStep', getters.SIGN_UP_EXTRA_INFO());
        } else {
          this.$toast.show('Error updating org.', this.toasted_options.error);
        }
      }
    },
    back() {
      this.$store.commit('setSelectedStep', getters.SIGN_UP_PERSONAL_INFO());
    },
    async get_info() {
      let response = await axios.get(this.organizationUrl + "get_signup_info")
      if (response.data.success) {
        this.form.name = response.data.result.name ? response.data.result.name : '';
        this.form.theme_id = response.data.result.theme_id ? response.data.result.theme_id : '';
      }
    },
  }
};
</script>
