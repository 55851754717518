<template>
  <div class="progressbar">
    <ul class="progressbar-container">
      <li>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="32" height="32" rx="16" fill="#3AB574"/>
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M22.7071 11.2929C23.0976 11.6834 23.0976 12.3166 22.7071 12.7071L14.7071 20.7071C14.3166 21.0976 13.6834 21.0976 13.2929 20.7071L9.29289 16.7071C8.90237 16.3166 8.90237 15.6834 9.29289 15.2929C9.68342 14.9024 10.3166 14.9024 10.7071 15.2929L14 18.5858L21.2929 11.2929C21.6834 10.9024 22.3166 10.9024 22.7071 11.2929Z"
                fill="white"/>
        </svg>
        <span>Create Account</span>
      </li>
      <li :class="{'active': selectedStep == 'sign-up-personal-info'}">
        <svg v-if="selectedStep == 'sign-up-business-info' || selectedStep=='sign-up-payment-info' || selectedStep=='sign-up-extra-info'  " width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="32" height="32" rx="16" fill="#3AB574"/>
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M22.7071 11.2929C23.0976 11.6834 23.0976 12.3166 22.7071 12.7071L14.7071 20.7071C14.3166 21.0976 13.6834 21.0976 13.2929 20.7071L9.29289 16.7071C8.90237 16.3166 8.90237 15.6834 9.29289 15.2929C9.68342 14.9024 10.3166 14.9024 10.7071 15.2929L14 18.5858L21.2929 11.2929C21.6834 10.9024 22.3166 10.9024 22.7071 11.2929Z"
                fill="white"/>
        </svg>
        <svg v-if="selectedStep == 'sign-up-personal-info'" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="1" y="1" width="30" height="30" rx="15" fill="white"/>
          <circle cx="16" cy="16" r="5" fill="#3AB574"/>
          <rect x="1" y="1" width="30" height="30" rx="15" stroke="#3AB574" stroke-width="2"/>
        </svg>
        <span>Personal Information</span>
      </li>
      <li :class="{'active': selectedStep == 'sign-up-business-info'}">
        <svg v-if="selectedStep == 'sign-up-business-info'" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="1" y="1" width="30" height="30" rx="15" fill="white"/>
          <circle cx="16" cy="16" r="5" fill="#3AB574"/>
          <rect x="1" y="1" width="30" height="30" rx="15" stroke="#3AB574" stroke-width="2"/>
        </svg>
        <svg v-if="selectedStep=='sign-up-payment-info' || selectedStep =='sign-up-extra-info' " width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="32" height="32" rx="16" fill="#3AB574"/>
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M22.7071 11.2929C23.0976 11.6834 23.0976 12.3166 22.7071 12.7071L14.7071 20.7071C14.3166 21.0976 13.6834 21.0976 13.2929 20.7071L9.29289 16.7071C8.90237 16.3166 8.90237 15.6834 9.29289 15.2929C9.68342 14.9024 10.3166 14.9024 10.7071 15.2929L14 18.5858L21.2929 11.2929C21.6834 10.9024 22.3166 10.9024 22.7071 11.2929Z"
                fill="white"/>
        </svg>
        <svg  v-if="selectedStep!='sign-up-business-info' && selectedStep != 'sign-up-payment-info' && selectedStep != 'sign-up-extra-info'" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="1" y="1" width="30" height="30" rx="15" fill="white"/>
          <rect x="1" y="1" width="30" height="30" rx="15" stroke="#D1D5DB" stroke-width="2"/>
        </svg>
        <span>Business Information</span>
      </li>
       <li :class="{'active': selectedStep == 'sign-up-extra-info'}">
        <svg v-if="selectedStep == 'sign-up-extra-info'" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="1" y="1" width="30" height="30" rx="15" fill="white"/>
          <circle cx="16" cy="16" r="5" fill="#3AB574"/>
          <rect x="1" y="1" width="30" height="30" rx="15" stroke="#3AB574" stroke-width="2"/>
        </svg>
        <svg v-if="selectedStep=='sign-up-payment-info'" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="32" height="32" rx="16" fill="#3AB574"/>
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M22.7071 11.2929C23.0976 11.6834 23.0976 12.3166 22.7071 12.7071L14.7071 20.7071C14.3166 21.0976 13.6834 21.0976 13.2929 20.7071L9.29289 16.7071C8.90237 16.3166 8.90237 15.6834 9.29289 15.2929C9.68342 14.9024 10.3166 14.9024 10.7071 15.2929L14 18.5858L21.2929 11.2929C21.6834 10.9024 22.3166 10.9024 22.7071 11.2929Z"
                fill="white"/>
        </svg>
        <svg  v-if="selectedStep!='sign-up-extra-info' && selectedStep != 'sign-up-payment-info'" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="1" y="1" width="30" height="30" rx="15" fill="white"/>
          <rect x="1" y="1" width="30" height="30" rx="15" stroke="#D1D5DB" stroke-width="2"/>
        </svg>
        <span>Get Started</span>
      </li>
      <li :class="{'active': selectedStep == 'sign-up-payment-info'}" v-if="!urlParam && !superOrg  && parseInt(trialAmount) > 0">
        <svg v-if="selectedStep == 'sign-up-payment-info'" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="1" y="1" width="30" height="30" rx="15" fill="white"/>
          <circle cx="16" cy="16" r="5" fill="#3AB574"/>
          <rect x="1" y="1" width="30" height="30" rx="15" stroke="#3AB574" stroke-width="2"/>
        </svg>
        <svg v-if="selectedStep != 'sign-up-payment-info'" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="1" y="1" width="30" height="30" rx="15" fill="white"/>
          <rect x="1" y="1" width="30" height="30" rx="15" stroke="#D1D5DB" stroke-width="2"/>
        </svg>
        <span>Payment Information</span>
      </li>
    </ul>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "progress-bar",
  computed: {
    ...mapState(['selectedStep','urlParam','trialAmount', 'superOrg']),
  },
}
</script>

<style scoped>

</style>