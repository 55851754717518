const setSelectedStep = function (state, payload) {
    state.selectedStep = payload;
};

const setTrialAmount = function (state, payload) {
    state.trialAmount = payload;
};

const setTrialDays = function (state, payload) {
    state.trialDays = payload;
};

const setRequestedOrganization = function(state, payload) {
    state.requestedOrganization = payload
};

const setUrlParam = function (state, payload) {
  state.urlParam = payload;
};

const setCurrentPlan = function(state,payload){
    state.currentPlan = payload;
}

const setAccountType = function(state,payload) {
    state.accountType = payload;
}

const setSuperOrg = function(state,payload) {
    state.superOrg = payload;
}

const setFbClickId = function(state,payload) {
    state.fbClickId = payload;
}

const setCopyCode = function(state,payload) {
    state.copyCode = payload;
}


export default {
    setCopyCode,
    setFbClickId,
    setSelectedStep,
    setTrialAmount,
    setTrialDays,
    setUrlParam,
    setCurrentPlan,
    setAccountType,
    setSuperOrg,
    setRequestedOrganization
}
