<template>
  <div>
    <UpperNavbar></UpperNavbar>
    <div class="row">
      <div class="col-md-4 col-sm-12">
        <ProgressBar></ProgressBar>
      </div>
      <div class="col-md-8 col-sm-12">
        <div>
          <div class="content-margins new-personal-info main-body">
            <div class="devise-form clear">
              <div>
                <p class="auth-p">Let's Start Your {{ trialDays }}-Day
                  {{ trialAmount === 0 ? 'Free' : '$' + trialAmount }} Trial</p>
                <form novalidate="novalidate" class="simple_form new_user" accept-charset="UTF-8" method="post">
                  <fieldset style="margin-top:5rem;">
                    <div class="input time_zone optional user_time_zone">
                      <div class="label">
                        <label class="time_zone optional business-info-label">Billing Information</label>
                      </div>
                      <div class="payment-card">
                        <div ref="card"></div>
                      </div>
                    </div>
                    <div class="business-footer-div">
                      <input
                          type="button"
                          @click="back"
                          value="Back"
                          tabindex="4"
                          class="btn button back-btn">
                      <input
                          type="button"
                          @click="submit"
                          :disabled="disableButton"
                          value="Pay"
                          tabindex="4"
                          class="btn button next-btn">
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
</style>

<script>

import axios from "axios";
import ApiUrls from "../../api-url";
import {mapGetters, mapState} from "vuex";
import getters from "../../stores/login-store/getters/login-getters";
import AuthenticationMixin from "../../authentication-mixin";
import ProgressBar from "../shared/progress-bar";
import UpperNavbar from "../shared/upper-navbar";


let style = {
  base: {
    border: '1px solid #D8D8D8',
    borderRadius: '4px',
    color: "#000",
  },

  invalid: {
    // All of the error styles go inside of here.
  }

};
let stripe = Stripe(Vue.prototype.$stripe_publish_key, style),
    elements = stripe.elements(),
    card = undefined;

export default {
  components: {ProgressBar, UpperNavbar},
  mixins: [ApiUrls, AuthenticationMixin],
  data() {
    return {
      disableButton: false,
    };
  },
  computed: {
    ...mapState(['trialAmount', 'trialDays']),
    ...mapGetters(['SIGN_UP_BUSINESS_INFO','SEND_YOUR_PHONE'])
  },
  async created() {

  },
  mounted: function () {
    card = elements.create('card');
    card.mount(this.$refs.card);
    this.$nextTick(() => {
      card.on('change', (event) => {
        if (event.complete) {
          this.disableButton = !event.complete;
        } else if (event.empty || event.error) {
          this.disableButton = true;
        }
      })
      this.validateCard();
    })
  },
  beforeDestroy() {
    card.destroy(this.$refs.card);
  },
  methods: {
    async submit() {
      stripe.createToken(card).then(async (result) => {
        await this.submitResponse(result.token);
      });
    },
    async submitResponse(token) {
      this.disableButton = true;
      let response = await axios.post(this.billingUrl + "update_billing_plan", {plan: null, token: token});
      if (response.data.success) {
          this.$store.commit('setSelectedStep', getters.SEND_YOUR_PHONE());
      } else {
        this.disableButton = false;
        this.$toast.show(response.data.result, this.toasted_options.error);
      }
    },
    back() {
      this.$store.commit('setSelectedStep', getters.SIGN_UP_EXTRA_INFO());
    },
    validateCard() {
      card.on("ready", () => {
        this.disableButton = true;
      });
    },
  }
};
</script>
