<template>
  <component
      v-if="!loading"
      :is="selectedStep"
  />
</template>

<script>
import axios from "axios";
import ApiUrls from "../api-url";
import LoginForm from "./signin/login-form";
import SignUpBusinessInfo from "./signup/sign-up-business-info";
import SignUpCreateAccount from "./signup/sign-up-create-account";
import SignUpPaymentInfo from "./signup/sign-up-payment-info";
import SignUpPersonalInfo from "./signup/sign-up-personal-info";
import getters from "../stores/login-store/getters/login-getters";
import SignUpExtraInfo from "./signup/sign-up-extra-info.vue";
import BookACall from "./signup/book-a-call.vue";
import AfterSignup from "./signup/after-signup.vue";
import SendYourPhone from "./signup/send-your-number.vue";
import {mapState} from "vuex";

export default {
  mixins: [ApiUrls],
  components: {
    LoginForm,
    SignUpBusinessInfo,
    SignUpCreateAccount,
    SignUpPaymentInfo,
    SignUpPersonalInfo,
    SignUpExtraInfo,
    AfterSignup,
    BookACall,
    SendYourPhone
  },
  data() {
    return {
      billingInfo: {
        trial_amount: 0,
        trial_days: 0
      },
      loading: false,
      user:{}
    };
  },
  async created() {
    this.loading = true;
    if (this.$route.name === "SignupPage") {
      this.$store.commit('setSelectedStep', getters.SIGN_UP_CREATE_ACCOUNT());
    } else if (this.$route.name === "CompleteSignupPage") {
      this.$store.commit('setSelectedStep', getters.SIGN_UP_PERSONAL_INFO());
    } else if (this.$route.name === "SigninPage") {
      this.$store.commit('setSelectedStep', getters.LOGIN_FORM());
    }
    this.getRequsetedOrganization()
    .then(() => this.loading = false)
    await this.getData();
  },
  computed: {
    ...mapState(['selectedStep']),
  },
  methods: {
    async getData() {
      const response = await axios.get(this.billingUrl + "get_system_billing_settings");
      this.billingInfo = response.data.result;
      this.setUpComponents();
    },
    async  getRequsetedOrganization() {
      let org_subdomain = window.location.host.split('.')[0];
      const response = await axios.post(this.organizationUrl + 'requested_organization', {org_subdomain})
      if (response.data.success) {
        await this.$store.commit('setRequestedOrganization', response.data.result);
      }
    },
    setUpComponents() {
      if (this.billingInfo.trial_amount === "0.0") {
        this.$store.commit('setTrialAmount', 0);
      } else {
        let amount = parseInt(this.billingInfo.trial_amount);
        this.$store.commit('setTrialAmount', amount);
      }
      this.$store.commit('setTrialDays', this.billingInfo.trial_days);
    },
    saveUser(user){
      this.user = user
    }
  }
};
</script>
