const LOGIN_FORM = function () {
    return 'login-form';
};

const SIGN_UP_CREATE_ACCOUNT = function () {
    return 'sign-up-create-account';
};

const SIGN_UP_PERSONAL_INFO = function () {
    return 'sign-up-personal-info';
};

const SIGN_UP_BUSINESS_INFO = function () {
    return 'sign-up-business-info';
};

const SIGN_UP_PAYMENT_INFO = function () {
    return 'sign-up-payment-info';
};

const SIGN_UP_EXTRA_INFO = function () {
    return 'sign-up-extra-info';
};

const BOOK_A_CALL = function (){
    return 'book-a-call';
}

const AFTER_SIGNUP  = function (){
    return 'after-signup';
}

const SEND_YOUR_PHONE = function (){
    return 'send-your-phone'
}


export default {
    LOGIN_FORM, SIGN_UP_CREATE_ACCOUNT, SIGN_UP_PERSONAL_INFO, SIGN_UP_BUSINESS_INFO, SIGN_UP_PAYMENT_INFO, SIGN_UP_EXTRA_INFO,BOOK_A_CALL,SEND_YOUR_PHONE, AFTER_SIGNUP
};
